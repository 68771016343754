import React, { useEffect, useState } from 'react';
import { withStore } from 'retalk';
import { Layout, message } from 'antd';
import { NavLink, Link, withRouter } from 'react-router-dom';

import fetch from '@/utils/fetch';
import Login from '@/components/Login';

import logo from '@/statics/logo.png';

import styles from './Layout.scss';

const { Header, Content } = Layout;

const BasicLayout = ({ children, history, addVisitor }) => {
  const [userInfo, setUserInfo] = useState({});
  const [loginModal, setLoginModal] = useState(false);
  const { pathname } = history.location;

  useEffect(() => {
    // addVisitor({ url: pathname });
    // history.listen((location) => {
    //   addVisitor({ url: location.pathname });
    // });
  }, []);

  useEffect(() => {
    // const getUserInfo = async () => {
    //   const result = await fetch('/api/user/info');
    //   if (result) {
    //     setUserInfo(result);
    //   } else {
    //     setLoginModal(true);
    //   }
    // };
    // if (pathname === '/' || pathname === '/personCenter' || pathname.includes('issueMessage')) {
    //   getUserInfo();
    // }
  }, [pathname]);

  const login = async (params) => {
    const result = await fetch('/api/user/login', { type: 'POST', params });
    if (result) {
      message.success(`欢迎，${result.username}`);
      setUserInfo(result);
      setLoginModal(false);
    }
  };

  const openLogin = () => {
    if (userInfo.username) {
      return;
    }
    setLoginModal(true);
  };

  return (
    <Layout>
      <Header>
        <NavLink to="/">
          <img alt="logo" src={logo} className={styles.logo} />
        </NavLink>
        <div className={styles.right}>
          {pathname !== '/' && (
            <Link className={styles.back} to="/">
              返回首页
            </Link>
          )}
          <a onClick={() => openLogin()}>{userInfo.username || '登录'}</a>
          <span className={styles.divider}>|</span>
          <NavLink to="/personCenter">用户中心</NavLink>
        </div>
      </Header>
      <Content>{children}</Content>
      <Login login={login} visible={loginModal} closeLogin={() => setLoginModal(false)} />
    </Layout>
  );
};

export default withRouter(withStore('basic')(BasicLayout));
