import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';

import AccountInfo from '@/pages/AccountInfo';
import ModifyPassword from '@/pages/ModifyPassword';
import IssueList from '@/pages/IssueList';
import fetch from '@/utils/fetch';
import styles from './index.scss';

const PersonCenter = ({ history }) => {
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    const getUserInfo = async () => {
      const result = await fetch('/api/user/info');
      if (result) {
        setUserInfo(result);
      }
    };
    getUserInfo();
  }, []);
  const logout = async () => {
    const result = await fetch('/api/user/logout', { type: 'POST' });
    if (result) {
      await message.success('注销成功');
      window.location.replace('/');
    }
    message.warning('注销失败');
  };
  const [flag, setFlag] = useState('我的发布');
  const renderColor = (val) => (flag === val ? 'rgba(29,85,255,1)' : 'rgba(34,34,34,1)');
  const renderPage = () => {
    if (flag === '我的发布') {
      return <IssueList />;
    }
    if (flag === '个人资料') {
      return <AccountInfo history={history} userInfo={userInfo} />;
    }
    if (flag === '修改密码') {
      return <ModifyPassword history={history} userInfo={userInfo} />;
    }
    return <div />;
  };
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.item}>
          <a onClick={() => setFlag('我的发布')} style={{ color: renderColor('我的发布') }}>
            我的发布
          </a>
        </div>
        <div className={styles.item}>
          <a onClick={() => setFlag('个人资料')} style={{ color: renderColor('个人资料') }}>
            个人资料
          </a>
        </div>
        <div className={styles.item}>
          <a onClick={() => setFlag('修改密码')} style={{ color: renderColor('修改密码') }}>
            修改密码
          </a>
        </div>
        <div className={styles.item}>
          <a
            onClick={() => {
              logout();
            }}
            style={{ color: renderColor('注销') }}
          >
            注销
          </a>
        </div>
      </div>
      <div>{renderPage()}</div>
    </div>
  );
};

export default withRouter(PersonCenter);
