/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { withStore } from 'retalk';
import { withRouter } from 'react-router-dom';
import { Form, Upload, message } from 'antd';

import fetch from '@/utils/fetch';

import issue from './image/issue_min.png';
import styles from './index.scss';

const IssueMessage = ({
  form: { getFieldDecorator, validateFields },
  submitIssue,
  clearShopInfo,
  history,
  match: {
    params: { id },
  },
}) => {
  const [shopInfo, setShopInfo] = useState({});
  const [imgUrl, setImgUrl] = useState('');

  useEffect(() => {
    const getUserInfo = async () => {
      const result = await fetch('/api/user/info');
      if (!result) {
        message.warn('您还未登录，请先点击登录');
        history.push('/');
      }
    };
    getUserInfo();
    const getDetail = async (params) => {
      const result = await fetch('/api/company/detail', { params });
      if (result) {
        setShopInfo(result);
        setImgUrl(result.company_img);
      }
    };
    if (id === 'add') {
      clearShopInfo();
    } else {
      getDetail({ company_id: Number(id) });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        if (id === 'add') {
          const result = await submitIssue({ ...values, company_img: imgUrl });
          if (result) {
            history.push('/');
          }
        } else {
          const result = await fetch('/api/company/update', {
            type: 'POST',
            params: {
              ...values,
              company_img: imgUrl,
              company_id: id,
            },
          });
          if (result) {
            history.push('/personCenter');
          }
        }
      }
    });
  };

  const beforeUpload = (file) => {
    // eslint-disable-next-line operator-linebreak
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('图片格式错误!');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('图片不能超过1M!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      setImgUrl(`http://${info.file.response.url}`);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.head}>发布信息</div>
      <div className={styles.text}>编辑和修改个人资料。</div>
      <Form onSubmit={handleSubmit}>
        <div className={styles.item}>
          <div className={styles.itemLabel}>标题：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('title', {
                initialValue: shopInfo.title || '',
                rules: [{ required: true, message: '请填写标题' }],
              })(<input placeholder="填写标题" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>公司名称：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('company_name', {
                initialValue: shopInfo.company_name || '',
                rules: [{ required: true, message: '请填写公司名称' }],
              })(<input placeholder="填写公司名称" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>公司地址：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('company_address', {
                initialValue: shopInfo.company_address || '',
                rules: [{ required: true, message: '请填写公司地址' }],
              })(<input placeholder="填写公司地址" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>业务经理：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('business_manager', {
                initialValue: shopInfo.business_manager || '',
                rules: [{ required: true, message: '请填写公司业务经理' }],
              })(<input placeholder="填写公司业务经理" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>联系方式：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('contact', {
                initialValue: shopInfo.contact || '',
                rules: [{ required: true, message: '请填写联系方式' }],
              })(<input placeholder="填写联系方式" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item_plus}>
          <div className={styles.itemLabel}>公司介绍：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('company_intro', {
                initialValue: shopInfo.company_intro || '',
                rules: [{ required: true, message: '请填写公司介绍信息' }],
              })(<textarea rows={4} className={styles.input_max} placeholder="填写公司介绍信息" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item_plus}>
          <div className={styles.itemLabel}>服务范围：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('business_scope', {
                initialValue: shopInfo.business_scope || '',
                rules: [{ required: true, message: '请填写服务范围信息' }],
              })(<textarea rows={4} className={styles.input_max} placeholder="填写服务范围信息" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item_plus}>
          <div className={styles.itemLabel}>公司优势：</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('company_advantage', {
                initialValue: shopInfo.company_advantage || '',
                rules: [{ required: true, message: '请填写公司优势信息' }],
              })(<textarea rows={4} className={styles.input_max} placeholder="填写公司优势信息" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item_avatar}>
          <div className={styles.label_avatar}>图 片：</div>
          <div className={styles.Wrap_avatar}>
            <Form.Item>
              {getFieldDecorator('company_img')(
                <div className={styles.avatar}>
                  <div className={styles.img}>
                    <Upload
                      name="file"
                      action="/api/upload"
                      showUploadList={false}
                      listType="picture"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      <img src={imgUrl || issue} alt="头像" />
                    </Upload>
                  </div>
                  <div className={styles.text_avatar}>
                    <div className={styles.comment}>
                      照片尺寸320*200px
                      <div style={{ height: '11px' }} />
                      图片要求PNG、JPG、JPEG
                    </div>
                  </div>
                </div>,
              )}
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <button htmltype="submit">提交</button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withRouter(withStore('issueMessage')(Form.create()(IssueMessage)));
