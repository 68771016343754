import React, { useEffect, useState } from 'react';
import { withStore } from 'retalk';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import fetch from '@/utils/fetch';
import Footer from '@/components/Footer';
// import banner from '@/statics/home_banner.png';
import banner from '@/statics/scene-banner.jpg';
import cake from '@/statics/scene-cake.png';
import commodity from '@/statics/scene-commodity.png';
import drug from '@/statics/scene-drug.png';
import flower from '@/statics/scene-flower.png';

import styles from './index.scss';

const Home = () => {
  const [keywords, setKeywords] = useState('');
  const [shopList, setShopList] = useState([]);

  const bannerList = [banner, cake, commodity, drug, flower];

  const getList = async (params) => {
    // const result = await fetch('/api/company/list', { params });
    // if (result) {
    //   setShopList(result.list);
    // }
  };

  useEffect(() => {
    getList({ current: 1, pageSize: 1000000 });
  }, []);

  const onSearch = () => {
    getList({ company_name: keywords });
  };

  return (
    <>
      <div className={styles.search}>
        <Input
          className={styles.searchInput}
          value={keywords}
          onChange={(e) => {
            if (!e.target.value) {
              getList();
            }
            setKeywords(e.target.value);
          }}
          allowClear
        />
        <button className={styles.searchButton} onClick={onSearch}>
          搜索
        </button>
        <Link to="/issueMessage/add">
          <button className={styles.dispatchButton}>发布消息</button>
        </Link>
      </div>
      {/* <div className={styles.topBannerContainer}>
        <div className={styles.textContainer}>
          <span className={styles.text}>助力门店线上化让经营更简单</span>
        </div>
        <img src={banner} alt="banner" className={styles.banner} />
      </div> */}
      {/* <div>

      </div> */}
      <div className={styles.content}>
        {bannerList.map((src) => (
          <img style={{ height: 'auto', width: '100%' }} src={src} alt="banner" />
        ))}
        {/* <img src="../../statics/scene-cake.jpg" alt="banner" />
        <img src="../../statics/scene-commodity.jpg" alt="banner" />
        <img src="../../statics/scene-drug.jpg" alt="banner" />
        <img src="../../statics/scene-flower.jpg" alt="banner" /> */}
        {shopList.map((item) => (
          <div className={styles.contentItem} key={item.id}>
            <img src={item.company_img} className={styles.photo} alt="" />
            <div className={styles.right}>
              <div className={styles.titleContainer}>
                <span className={styles.title}>{item.title}</span>
                <span>发布时间 {dayjs(item.publish_at).format('YYYY-MM-DD')}</span>
              </div>
              <div className={styles.item}>
                <div className={styles.subTitle}>公司名称：</div>
                <div className={styles.text}>{item.company_name}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.subTitle}>服务范围：</div>
                <div className={styles.text}>{item.business_scope}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.subTitle}>服务优势：</div>
                <div className={styles.text}>{item.company_advantage}</div>
              </div>
              <div className={styles.item}>
                <div className={styles.subTitle}>公司简介：</div>
                <div className={styles.text}>{item.company_intro}</div>
              </div>
              <div className={styles.bottomItem}>
                <div>
                  <span className={styles.subTitle}>业务经理：</span>
                  <span>{item.business_manager}</span>
                </div>
                <div>
                  <span className={styles.subTitle}>联系电话：</span>
                  <span>{item.contact}</span>
                </div>
                <Link to={`/detail/${item.id}`}>查看详情</Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default withStore('home')(Home);
