import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Divider } from 'antd';

import fetch from '@/utils/fetch';

import styles from './index.scss';

const columns = [
  {
    title: <div className={styles.title}>序号</div>,
    dataIndex: 'id',
    width: 100,
  },
  {
    title: <div className={styles.title}>提交时间</div>,
    dataIndex: 'submit_at',
    width: 400,
  },
  {
    title: <div className={styles.title}>发布时间</div>,
    dataIndex: 'publish_at',
    render: (text) => text || '-',
    width: 400,
  },
  {
    title: <div className={styles.title}>状态</div>,
    dataIndex: 'status',
    render: (text, row) => {
      const { status } = row;
      if (status === 0) {
        return '审核中';
      }
      if (status === 1) {
        return '未通过';
      }
      if (status === 2) {
        return '已发布';
      }
      if (status === 3) {
        return '已下线';
      }
      return '-';
    },
    width: 200,
  },
  {
    title: <div className={styles.title}>操作</div>,
    render: (text, row) => (
      <>
        <Link to={`/detail/${row.id}`}>查看</Link>
        <Divider type="vertical" />
        <Link to={`/issueMessage/${row.id}`}>编辑</Link>
      </>
    ),
    width: 400,
  },
];

const IssueList = () => {
  const [issueList, setIssueList] = useState([]);
  // eslint-disable-next-line camelcase
  const [cur_page, setCurPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const getlist = async (params) => {
    setLoading(true);
    const result = await fetch('/api/user/mypost', { params });
    if (result) {
      setIssueList(result.list);
      setTotal(result.total);
      setLoading(false);
      return true;
    }
    return true;
  };

  const onPageChange = (page) => {
    setCurPage(page);
    getlist({ current: page, pageSize: 10 });
  };
  useEffect(() => {
    getlist({ current: 1, pageSize: 10 });
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.head}>我的发布</div>
      <div className={styles.text}>查看和编辑我发布的公司信息。</div>
      <Table
        columns={columns}
        dataSource={issueList}
        loading={loading}
        pagination={{
          current: cur_page,
          pageSize: 10,
          onChange: onPageChange,
          total,
        }}
        rowKey="id"
        bordered
      />
    </div>
  );
};

export default IssueList;
