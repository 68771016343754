import { setStore } from 'retalk';
import DemoModel from '@/pages/Demo/model';
import BasicModel from '@/basic/model';
import HomeModel from '@/pages/Home/model';
import ChangeInfoModel from '@/pages/AccountInfo/model';
import ModifyPasswdModel from '@/pages/ModifyPassword/model';
import IssueModel from '@/pages/Issue/model';
import DetailModel from './pages/Detail/model';

const store = setStore({
  demo: DemoModel,
  basic: BasicModel,
  home: HomeModel,
  detail: DetailModel,
  changeInfo: ChangeInfoModel,
  changePassword: ModifyPasswdModel,
  issueMessage: IssueModel,
});

export default store;
