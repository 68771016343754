import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, message } from 'antd';
import theRealFetch from 'isomorphic-fetch';

import fetch from '@/utils/fetch';
import close from '@/statics/close.png';

import styles from './index.scss';

const Login = ({ login, visible, closeLogin }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [codeUrl, setCodeUrl] = useState('');
  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  });
  const [registerForm, setRegisterForm] = useState({
    username: '',
    password: '',
    email: '',
    phone: '',
    captcha: '',
  });

  const getCode = async () => {
    // theRealFetch('/api/user/getCaptchaCode')
    //   .then((res) => res.arrayBuffer())
    //   .then((data) => {
    //     const dataUrl = window.btoa(String.fromCharCode(...new Uint8Array(data)));
    //     setCodeUrl(`data:image/svg+xml;base64,${dataUrl}`);
    //   });
  };

  const register = async (params) => {
    const result = await fetch('/api/user/register', { type: 'POST', params });
    if (result) {
      message.success('注册成功');
      setIsRegister(false);
      setRegisterForm({
        username: '',
        password: '',
        email: '',
        phone: '',
        captcha: '',
      });
    } else {
      getCode();
    }
  };

  useEffect(() => {
    getCode();
  }, [visible]);

  const closeModal = () => {
    closeLogin();
    setIsRegister(false);
  };

  const submitLogin = () => {
    if (!loginForm.username) {
      message.error('请填写用户名');
      return;
    }
    if (!loginForm.password) {
      message.error('请填写密码');
      return;
    }
    login(loginForm);
  };

  const submitRegister = () => {
    if (!registerForm.email) {
      message.error('请填写邮箱');
      return;
    }
    if (!registerForm.username) {
      message.error('请填写用户名');
      return;
    }
    if (!registerForm.password) {
      message.error('请填写密码');
      return;
    }
    if (!registerForm.phone) {
      message.error('请填写手机号');
      return;
    }
    if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(registerForm.phone)) {
      message.error('手机号格式不正确');
      return;
    }
    if (!registerForm.captcha) {
      message.error('请填写验证码');
      return;
    }
    register(registerForm);
  };

  return (
    <Modal
      visible={visible}
      footer={null}
      closable={false}
      style={{ right: 43, top: 64, margin: 0, position: 'fixed' }}
    >
      <div className={styles.modalContainer}>
        <div onClick={() => closeModal()}>
          <img alt="close" src={close} className={styles.close} />
        </div>
        <div className={styles.form}>
          {isRegister ? (
            <>
              <div className={styles.formItem}>
                <div className={styles.label}>邮箱</div>
                <Input
                  placeholder="邮箱"
                  value={registerForm.email}
                  onChange={(e) => setRegisterForm({ ...registerForm, email: e.target.value })}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.label}>用户名</div>
                <Input
                  placeholder="用户名必须3-20个字符"
                  value={registerForm.username}
                  onChange={(e) => setRegisterForm({ ...registerForm, username: e.target.value })}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.label}>密码</div>
                <Input
                  placeholder="密码必须6-20个字符"
                  value={registerForm.password}
                  onChange={(e) => setRegisterForm({ ...registerForm, password: e.target.value })}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.label}>手机号</div>
                <Input
                  className={styles.small}
                  placeholder="手机号"
                  value={registerForm.phone}
                  onChange={(e) => setRegisterForm({ ...registerForm, phone: e.target.value })}
                />
              </div>
              <div className={styles.formItem}>
                <div className={styles.label}>验证码</div>
                <div className={styles.codeContainer}>
                  <Input
                    className={styles.small}
                    placeholder="验证码"
                    value={registerForm.captcha}
                    onChange={(e) => setRegisterForm({ ...registerForm, captcha: e.target.value })}
                  />
                  <div onClick={() => getCode()}>
                    <img alt="" src={codeUrl} className={styles.code} />
                  </div>
                </div>
              </div>
              <Button onClick={() => submitRegister()}>注册</Button>
              <a className="action" onClick={() => setIsRegister(false)}>
                登录
              </a>
            </>
          ) : (
            <>
              <div className={styles.formItem} style={{ marginBottom: 50 }}>
                <div className={styles.label}>用户名</div>
                <Input
                  placeholder="用户名"
                  value={loginForm.username}
                  onChange={(e) => setLoginForm({ ...loginForm, username: e.target.value })}
                />
              </div>
              <div className={styles.formItem} style={{ marginBottom: 50 }}>
                <div className={styles.label}>密码</div>
                <Input
                  placeholder="密码"
                  type="password"
                  value={loginForm.password}
                  onChange={(e) => setLoginForm({ ...loginForm, password: e.target.value })}
                />
              </div>
              <Button onClick={submitLogin}>登录</Button>
              <a
                className="action"
                onClick={() => {
                  setIsRegister(true);
                  getCode();
                }}
              >
                注册
              </a>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default Login;
