import React, { useEffect, useState } from 'react';
import { withStore } from 'retalk';
import { withRouter } from 'react-router-dom';
import dayjs from 'dayjs';
import fetch from '@/utils/fetch';

import Footer from '@/components/Footer';

import styles from './index.scss';

const Detail = ({
  match: {
    params: { id },
  },
}) => {
  const [shopList, setShopList] = useState([]);
  const [shopInfo, setShopInfo] = useState({});

  const getDetail = async (params) => {
    const result = await fetch('/api/company/detail', { params });
    if (result) {
      setTimeout(() => {
        setShopInfo(result);
      }, 500);
    }
  };

  useEffect(() => {
    getDetail({ company_id: Number(id) });

    const getList = async (params) => {
      const result = await fetch('/api/company/list', { params });
      if (result) {
        setShopList(result.list);
      }
    };
    getList({ pageSize: 3 });
  }, []);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>{shopInfo.title}</div>
          {shopInfo.status === 2 && (
            <div className={styles.time}>
              发布时间 {dayjs(shopInfo.publish_at).format('YYYY-MM-DD')}
            </div>
          )}
          <img className={styles.photo} src={shopInfo.company_img} alt="" />
          <div className={styles.section}>
            <div className={styles.subTitle}>公司名称</div>
            <div>{shopInfo.company_name}</div>
          </div>
          <div className={styles.section}>
            <div className={styles.subTitle}>服务范围</div>
            <div>{shopInfo.business_scope}</div>
          </div>
          <div className={styles.section}>
            <div className={styles.subTitle}>服务优势</div>
            <div>{shopInfo.company_advantage}</div>
          </div>
          <div className={styles.section}>
            <div className={styles.subTitle}>公司信息</div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>简介：</div>
              <div>{shopInfo.company_intro}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>地址：</div>
              <div>{shopInfo.company_address}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>电话：</div>
              <div>{shopInfo.contact}</div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemTitle}>业务经理：</div>
              <div>{shopInfo.business_manager}</div>
            </div>
          </div>
          <div className={styles.subTitle}>您可能感兴趣</div>
          {shopList &&
            shopList.map((item) => (
              <div className={styles.shop} key={item.id}>
                <img className={styles.avatar} src={item.company_img} alt="" />
                <div className={styles.right}>
                  <div className={styles.shopTitleContainer}>
                    <span className={styles.shopTitle}>{item.company_name}</span>
                    <span>{dayjs(item.publish_at).format('YYYY-MM-DD')}</span>
                  </div>
                  <div className={styles.text}>{item.business_scope}</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(withStore('detail')(Detail));
