import React from 'react';
import { Provider } from 'retalk';
import { HashRouter, Switch, Route } from 'react-router-dom';

import store from '@/store';
import BasicLayout from '@/basic/Layout';
import Home from '@/pages/Home';
import Detail from '@/pages/Detail';
import PersonCenter from '@/pages/PersonCenter';
import IssueModel from '@/pages/Issue';

import './App.scss';

const App = () => (
  <HashRouter>
    <Provider store={store}>
      <BasicLayout>
        <Switch>
          <Route path="/detail/:id">
            <Detail />
          </Route>
          <Route path="/personCenter">
            <PersonCenter />
          </Route>
          <Route path="/issueMessage/:id">
            <IssueModel />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
        </Switch>
      </BasicLayout>
    </Provider>
  </HashRouter>
);

export default App;
