import React from 'react';

// eslint-disable-next-line no-unused-vars
import styles from './index.scss';

const Footer = () => (
  <>
    <div className="company">
      <div className="company-logo" />
      <div className="company-info">
        <div className="info-col">
          <div className="info-title">快速导航</div>
          <a href="http://sf-cityrush.com/" className="info-subtitle">
            顺丰同城急送
          </a>
        </div>
        <div className="info-col">
          <div className="info-title">联系我们</div>
          <div
            style={{
              fontWeight: 500,
              fontSize: 26,
              lineHeight: '37px',
              marginBottom: 12,
            }}
          >
            9533868
          </div>
          <div className="info-subtitle">工作日 9:00 - 18:00 </div>
        </div>
        <div className="info-col">
          <div className="info-title">咨询</div>
          <div className="qrcode" />
          <div style={{ fontSize: 12, width: 72, lineHeight: '17px', opacity: 0.7 }}>
            微信扫码关注 了解最新资讯
          </div>
        </div>
      </div>
    </div>
    <div className="real-footer">
      北京市海淀区安宁庄东路7号三层301
      <br />
      <a href="https://beian.miit.gov.cn/" rel="noreferrer" target="_blank">
        京ICP备20015252号-1
      </a>
    </div>
  </>
);

export default Footer;
