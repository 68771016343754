/* eslint-disable camelcase */
/* eslint-disable operator-linebreak */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { withStore } from 'retalk';
import { Form, Button, Input, message } from 'antd';
import styles from './index.scss';

const ModifyPassword = ({
  form: { getFieldDecorator, validateFields },
  changePassword,
  userInfo,
  history,
}) => {
  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const { new_password, re_password } = values;
        if (new_password !== re_password) {
          return message.warning('新密码输入不一致');
        }
        delete values.re_password;
        if (new_password.length < 6 || new_password.length > 20) {
          return message.warning('密码最少6位，最多20位');
        }
        const result = await changePassword(values);
        if (result) {
          history.push('/');
        }
      }
      return true;
    });
  };
  return (
    <div className={styles.container}>
      <div className={styles.head}>修改密码</div>
      <div className={styles.text}>查看账号，输入旧密码变更新密码。</div>
      <div className={styles.text}>登录账号：{userInfo.username}</div>
      <Form onSubmit={handleSubmit}>
        <Form.Item>
          {getFieldDecorator('old_password', {
            rules: [{ required: true, message: '请输入当前密码' }],
          })(<Input type="password" placeholder="请输入当前密码" />)}
        </Form.Item>

        <Form.Item>
          {getFieldDecorator('new_password', {
            rules: [{ required: true, message: '请输入新密码' }],
          })(<Input type="password" placeholder="请输入新密码" />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('re_password', {
            rules: [{ required: true, message: '再输入新密码' }],
          })(<Input type="password" placeholder="再输入新密码" />)}
        </Form.Item>

        <Form.Item>
          <Button htmltype="submit">确定</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withStore('changePassword')(Form.create()(ModifyPassword));
