/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { withStore } from 'retalk';
import { Form, Input, Button, Upload, message } from 'antd';
import issue from './image/issue_min.png';
import styles from './index.scss';

const AccountInfo = ({
  form: { getFieldDecorator, validateFields },
  userInfo,
  changeInfo,
  history,
}) => {
  const [imgUrl, setImgUrl] = useState(userInfo.user_img || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        delete values.username;
        delete values.email;
        const result = await changeInfo({ ...values, user_img: imgUrl });
        if (result) {
          history.push('/');
          return true;
        }
      }
      return true;
    });
  };

  const beforeUpload = (file) => {
    // eslint-disable-next-line operator-linebreak
    const isJpgOrPng =
      file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
    if (!isJpgOrPng) {
      message.error('图片格式错误!');
    }
    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      message.error('图片不能超过1M!');
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      setImgUrl(`http://${info.file.response.url}`);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.head}>个人资料</div>
      <div className={styles.text}>编辑和修改个人资料。</div>
      <Form onSubmit={handleSubmit}>
        <div className={styles.item_avatar}>
          <div className={styles.label_avatar}>头像:</div>
          <div className={styles.Wrap_avatar}>
            <Form.Item>
              {getFieldDecorator(
                'user_img',
                {},
              )(
                <div className={styles.avatar}>
                  <div className={styles.img}>
                    <Upload
                      name="file"
                      action="/api/upload"
                      showUploadList={false}
                      listType="picture"
                      beforeUpload={beforeUpload}
                      onChange={handleChange}
                    >
                      <img src={imgUrl || issue} alt="头像" />
                    </Upload>
                  </div>
                  <div className={styles.upload}>
                    <div className={styles.comment}>
                      照片尺寸100*100px
                      <div style={{ height: '11px' }} />
                      图片要求PNG、JPG、JPEG
                    </div>
                  </div>
                </div>,
              )}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>用户名:</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator(
                'username',
                {},
              )(<span>{(userInfo && userInfo.username) || ''}</span>)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>昵 称:</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('nickname', {
                initialValue: userInfo.nickname,
                rules: [{ required: true, message: '请填写昵称信息' }],
              })(<Input placeholder="填写昵称信息" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>个人介绍:</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('user_intro', {
                initialValue: userInfo.user_intro,
                rules: [{ required: true, message: '请填写个人介绍信息' }],
              })(<Input placeholder="填写个人介绍信息" />)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>邮 箱:</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('email', {})(<span>{(userInfo && userInfo.email) || ''}</span>)}
            </Form.Item>
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.itemLabel}>手机号:</div>
          <div className={styles.itemWrap}>
            <Form.Item>
              {getFieldDecorator('phone', {
                initialValue: userInfo.phone,
                rules: [{ required: true, message: '请填写电话信息' }],
              })(<Input placeholder="131 3333 9999" />)}
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <Button htmltype="submit">确定</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default withStore('changeInfo')(Form.create()(AccountInfo));
